import { russianDefaultPaymentCards } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'Октябрьский, д. 85',
		place: null,
		phoneNumbers: ['8 (812) 643-03-25', '8 (909) 582-18-99'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinopolisubiley',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianDefaultPaymentCards,
		},
		maps: 'https://yandex.ru/maps/-/CBaHfCBasD',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
